<script>
import NavHeader from "@/components/top/NavHeader.vue";
import PolicyFooter from "@/views/policy/public/policyFooter.vue";
import {useRoute, useRouter} from "vue-router";
import {reactive} from 'vue'
import XiaLa from "@/components/page/XiaLa.vue";
import PolicyList from "@/views/policy/public/policyList.vue";

export default {
  name: 'PolicyCollect',
  components: {PolicyList, XiaLa, PolicyFooter, NavHeader},
  setup() {
    const route = useRoute()
    const router = useRouter()
    const where = reactive({
      is_shou_cang: true
    })

    return {
      router,
      where,
      route
    }
  }
}
</script>

<template>
  <div class="tal-h-full tal-flex tal-flex-col">
    <div>
      <nav-header :title="route.meta.title"/>
    </div>
    <div class="tal-flex-1 tal-overflow-auto">
      <xia-la :where="where" axios-type="post" get-url="/gzhphp/policy/get_policy_list">
        <template #list="{list}">
          <div class="tal-pl-4 tal-pr-4 tal-flex tal-flex-col tal-gap-4">
            <template
                v-for="(vo,i) in list"
                :key="i"
            >
              <div
                  @click="router.push({name:'policyInfo',params:{id:vo.id}})"
              >
                <policy-list :info="vo"/>
              </div>
            </template>
          </div>
        </template>
      </xia-la>
    </div>
    <div>
      <policy-footer/>
    </div>
  </div>
</template>

<style scoped lang="less">

</style>

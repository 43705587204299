<template>
  <div>
    <div class="tal-fixed tal-bottom-0 tal-w-full tal-z-10">
      <div class="footer-list">
        <div class="ne-rong" v-for="vo in getNavList" :key="vo.id">
          <div class="ne-rong-div" @click="tiaoZhuan(vo)" :class="{'faBu':vo.pathName==='faBu'}">
            <div class="icon">
              <img
                  :src="pageName===vo.name?vo.gui_dang_zi_liao:vo.icon"
              >
            </div>
            <div class="name" :class="{'tal-text-zhu-se':pageName===vo.name}">
              {{ vo.name }}
            </div>
          </div>
        </div>
        <fa-bu-xu-qiu ref="faBu"/>
      </div>
    </div>
    <div class="zhan-wei" v-if="fixed">
    </div>
  </div>
  <guan-jia ref="guanJia"/>
</template>

<script>
import FaBuXuQiu from "@/views/open/FaBuXuQiu";
import GuanJia from "@/components/select/GuanJia";
import http from "@/api/http";
import toJSONString from "xe-utils/toJSONString";
import toStringJSON from "xe-utils/toStringJSON";
import {get, toInteger} from "xe-utils";
import {getPolicyFooterData} from "@/api/dataList/publicApi";

export default {
  name: 'policyFooter',
  components: {
    GuanJia,
    FaBuXuQiu,
  },
  props: {
    fixed: Boolean,
    pathName: String
  },
  data() {
    return {
      navList: [],
      pageName:'',
    }
  },
  mounted() {
    this.pageName = get(this.$route,'meta.title','')
    getPolicyFooterData().then(da=>{
      if (da.code === 1) {
        this.navList = da.data
      }
    })

  },
  methods: {
    tiaoZhuan(navListItem) {
      if (navListItem.pathName === 'faBu') {
        this.$refs.guanJia.show()
        return
      }
      location.href = navListItem.href
    }
  },
  computed: {
    //获取底部导航
    getNavList() {
      let selfNavList = toStringJSON(toJSONString(this.navList))

      if (selfNavList.length % 2 === 0) {
        selfNavList.splice(toInteger(selfNavList.length / 2), 0, {
          name: '管家',
          pathName: 'faBu',
          icon: 'http://yunzuofile.sxsgky.com/hui_qu_zheng_ce/guan_jia.png',
          gui_dang_zi_liao: 'http://yunzuofile.sxsgky.com/hui_qu_zheng_ce/guan_jia.png',
        })
      }
      return selfNavList
    }
  }
}
</script>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var.less";

@div-height: 55px;
.footer-list {
  z-index: 17;
  height: @div-height;
  background-color: #fff;
  box-shadow: 0 -2px 4px #ecdddd;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-end;

  .ne-rong {
    text-align: center;
    //flex: 1;
    width: 100%;
    position: relative;

    /*  .zhe-zhao.faBu {
        position: absolute;
        width: 100%;
        height: 30px;
        background-color: @white;
        top: 0;
        left: 0;
        right: 0;
      }*/

    .ne-rong-div {
      padding-bottom: 5px;

      &.faBu {
        position: absolute;
        top: -85px;
        border-radius: 100px;
        left: 0;
        right: 0;
        background-color: @white;
        width: 58px;
        height: 58px;
        margin: auto;
        box-shadow: 0 -2px 4px #f3e9e9;
        z-index: 10;

        .icon {
          margin-top: 10px;
          margin-left: 10px;
          width: 38px;
          height: 38px;
          background-color: @blue;
          border-radius: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .name {
          color: @blue !important;
          margin-top: 5px;
          font-size: 14px;
        }
      }

      .name {
        font-size: 12px;
      }

      .icon {
        img {
          width: 23px;
          height: 23px;
        }
      }
    }

  }
}

.zhan-wei {
  height: 65px;
}

</style>

<script>
import {huiQiZhengCeStatus, pingJieDiZhi} from "@/package/gongJu";
import moment from "moment/moment";
import {toNumber,commafy} from "xe-utils";
import YzTag from "@/package/YzTag/index.vue";

export default {
  name: 'policyList',
  components: {YzTag},
  methods: {pingJieDiZhi},
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    function isBetween() {
      return huiQiZhengCeStatus(props.info.shen_bao_start_time, props.info.shen_bao_end_time)
    }
    function isJiangLi(val) {
      return val !== ''
    }
    return {
      toNumber,
      commafy,
      isJiangLi,
      isBetween,
      moment
    }
  }

}
</script>

<template>
  <div
      class="tal-bg-white tal-p-3 tal-shadow-sm tal-rounded-lg tal-overflow-hidden tal-flex tal-flex-col tal-gap-4"
  >
    <div class="tal-text-2xl">{{ info.name }}</div>
    <div class="tal-flex tal-gap-2 tal-flex-wrap" >
        <yz-tag :background-color="isBetween().bg" :text-color="isBetween().text">{{ isBetween().name }}</yz-tag>

      <yz-tag background-color="#EDECF3" text-color="#4A4096">{{ info.ji_bie_name }}</yz-tag>
      <yz-tag
          v-for="(v2,i2) in pingJieDiZhi(info.level,info.join_area)"
          background-color="#EDECF3" text-color="#4A4096"
          :key="i2"
      >
        {{ v2 }}
      </yz-tag>
      <yz-tag
          background-color="#FFE5E7"
          text-color="#FF617A"
          v-if="isJiangLi(info.jiang_jing)"
      >
        有奖励
      </yz-tag>
      <yz-tag
          background-color="#E3E3E3"
          text-color="#919090"
          v-else
      >
        无奖励
      </yz-tag>

    </div>
    <div class="tal-text-gray-400" v-if="isJiangLi(info.jiang_jing)">
      奖励金额：{{ info.jiang_jing }}
    </div>
    <div class="tal-text-gray-400">
      部门：{{ info.bu_men_name }}
    </div>
    <div class="tal-text-gray-400">
      申报时间：{{ moment(info.shen_bao_start_time).format("yyyy-MM-DD") }} 至
      {{ moment(info.shen_bao_end_time).format("yyyy-MM-DD") }}
    </div>
  </div>
</template>

<style scoped lang="less">

</style>

<template>
  <div>
    <van-action-sheet v-model:show="pageStatus">
      <van-form @submit="onSubmit">
        <div class="bg-aaa">
          <van-cell-group class="" inset>
            <van-field
                required
                v-model="form.title"
                label="项目描述"
                placeholder="例如：绍兴纺织厂新建环评"
                type="textarea"
                rows="2"
                autosize
            />
            <van-field
                required
                :readonly="isAutoMobile"
                v-model="form.mobile"
                label="手机号"
                placeholder="请输入手机号"

            />
            <van-field
                required
                v-model="form.code"
                label="验证码"
                placeholder="请输入验证码"
                v-if="!isAutoMobile"
            >
              <template #button>
                <a href="javascript:" @click="getCode(form.mobile)">{{ getCodeTitle }}</a>
              </template>
            </van-field>
            <model-and-code/>

          </van-cell-group>

          <van-collapse v-model="activeNames">
            <van-collapse-item name="xiang-xi" title="">
              <template #value>详细信息</template>
            </van-collapse-item>
          </van-collapse>

          <van-cell-group inset v-if="activeNames.includes('xiang-xi')">
            <Address
                v-model:id="form.address_id"
                v-model:name="form.address_name"
                placeholder="请选择地区"
            />
            <fu-wu-shang-type
                title="类型"
                input-type="radio"
                placeholder="请选择类型"
                v-model:id="form.type_id"
                v-model:name="form.type_name"
            />
            <van-field
                :label="ping_jie_find.hang_ye"
                v-model="form.hang_ye"
                placeholder="例如：纺织、化工、医药、化工等"
            />
            <van-field
                :label="ping_jie_find.mu_di"
                v-model="form.mu_di"
                placeholder="例如：立项、贷款、新建或技改项目等"
            />
            <van-field
                :label="ping_jie_find.zong_tou_zi"
                v-model="form.zong_tou_zi"
                placeholder="请输入总投资金额"
            />
            <van-field
                :label="ping_jie_find.tu_di"
                v-model="form.tu_di"
                placeholder="请输入项目占地多少亩"
            />
            <van-field
                :label="ping_jie_find.qi_ta"
                v-model="form.qi_ta"
                placeholder="项目其他情况说明"
            />
            <!--
            <Address
                v-model:id="form.address_id"
                v-model:name="form.address_name"
            />

            <van-field
                v-model.number="form.money"
                label="预算金额"
                type="number"
                placeholder="请输入预算金额"
            />-->
            <model-and-code/>

          </van-cell-group>
        </div>
        <alert-button>
          提交
        </alert-button>
      </van-form>
    </van-action-sheet>
  </div>
</template>

<script>
//发布需求
import AlertButton from "./alertButton";
import yanZhengMa from "./yanZhengMa";
import Address from "../../components/select/Address";
import FuWuShangType from "../../components/select/FuWuShangType";
import http from "../../api/http";
import XEUtils from "xe-utils";
import _ from 'lodash'

export default {
  name: 'FaBuXuQiu',
  components: {FuWuShangType, AlertButton, Address},
  mixins: [yanZhengMa],
  data() {
    return {
      form: {
        title:''
      },
      activeNames: [],
      pageStatus: false,
      ping_jie_find:{
        hang_ye:"行业",
        mu_di:'目地',
        zong_tou_zi:'总投资',
        tu_di:'土地面积',
        qi_ta:'其他'
      }
    }
  },
  methods: {
    show() {
      this.pageStatus = true
      this.getMobiel().then(mobile => {
        this.form.mobile = mobile
      })
    },
    onSubmit() {
      let data = XEUtils.toStringJSON(XEUtils.toJSONString(this.form))

      _.forEach(this.ping_jie_find,(n,key)=>{
        let val = _.get(data,key)
        if(!_.isUndefined(val) && !_.isEmpty(val) ){
          data.title+=` ${n}:${val}`
        }
      })

      http.post("/gzhphp/fu_wu_shang/addXuQiu", data, {
        load_success: true
      }).then(da => {
        if (da.code === 1) {
          this.pageStatus = false
          XEUtils.clear(this.form, undefined)
          if (!XEUtils.isUndefined(window.faBuevent)) {
            window.faBuevent()
          }
        }
      })
    }
  }

}
</script>
<style scoped>
.xu-qiu {
  padding-top: 20px;
}
</style>

<template>
  <div>
    <van-share-sheet
        teleport="body"
        v-model:show="showShare"
        :options="options"
        @select="onSelect"
    />
  </div>
  <fa-bu-xu-qiu ref="xuQIu" />
</template>

<script>
//联系客服
import http from "../../api/http.js";
import FaBuXuQiu from "@/views/open/FaBuXuQiu";
import {getZaiXianKeFu} from "@/api/dataList/publicApi";
export default {
  name: 'GuanJia',
  components: {FaBuXuQiu},
  data() {
    return {
      showShare: false,
      options: [
        {name: '在线客服', type: '在线客服', icon: 'http://yunzuofile.sxsgky.com/2021121814403726791.png'},
        {name: '发布需求', type: '发布需求', icon: 'http://yunzuofile.sxsgky.com/2021121814410999424.png'},
        {name: '客服电话', type: '客服电话', icon: 'http://yunzuofile.sxsgky.com/2021121814412971877.png'},
      ]
    }
  },

  methods: {
    show() {
      this.showShare = true
    },
    onSelect(option) {
      this.showShare = false;
      if (option.type === '在线客服') {
        getZaiXianKeFu().then(da=>{
          if (da.code === 1) location.href = da.data.content
        })
        return
      }

      if (option.type === '客服电话') {
        http.get('/portal/index/get_ke_hu_tel').then(da => {
          if (da.code === 1) location.href = `tel:${da.data.content}`
        })
        return
      }

      this.$refs.xuQIu.show()

    }
  }
}
</script>

<style scoped>

</style>
